import React from 'react';
import './LocationDisplay.css';
import bergen from './img/bergen.jpg';
import oslo from './img/oslo.jpg';
import officeOslo from './img/kontor_oslo.jpg';
import officeBergen from './img/kontor_bergen.jpeg';
import Distance from './DistanceDisplay';

import Forecast from './Forecast';

const locations = ["bergen", "oslo"];

const locationConfig = {
	bergen: {
		name: "WAYS Bergen",
		location: {
			lat: 60.3956414,
			long: 5.3185947
		},
		images: {
			near: `${officeBergen}`,
			far: `${bergen}`
		}
	},
	oslo: {
		name: "WAYS Oslo",
		location: {
			lat: 59.9122979,
			long: 10.7391073
		},
		images: {
			near: `${officeOslo}`,
			far: `${oslo}`
		}
	}
}

function getNearest(position, locations)
{
	let nearestDist = 9999999;
	let nearest = null;
	for (let i = 0; i < locations.length; i++)
	{
		const locationInfo = locations[i];
		const dist = objectDistance(position, locationInfo.location);
		if (dist < nearestDist)
		{
			nearestDist = dist;
			nearest = locationInfo;
		}
	}
	return nearest;
}

function distIsNegligible(distance)
{
	return distance < 500 ? true : false;
}

function circleDistance(lat1, lon1, lat2, lon2){  // generally used geo measurement function
	//Haversine formula, source: b-h- at stackoverflow (https://stackoverflow.com/users/329130/b-h)
	var R = 6378.137; // Radius of earth in KM
	var dLat = lat2 * Math.PI / 180 - lat1 * Math.PI / 180;
	var dLon = lon2 * Math.PI / 180 - lon1 * Math.PI / 180;
	var a = Math.sin(dLat/2) * Math.sin(dLat/2) +
	Math.cos(lat1 * Math.PI / 180) * Math.cos(lat2 * Math.PI / 180) *
	Math.sin(dLon/2) * Math.sin(dLon/2);
	var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
	var d = R * c;
	return d * 1000; // meters
}

function objectDistance(start, end)
{
	return circleDistance(start.lat, start.long, end.lat, end.long);
}
class LocationDisplay extends React.Component
{
	render() {
		const position = {lat: this.props.lat, long: this.props.long};
		const closest = getNearest(position,locations.map( (name) => {
			return locationConfig[name];
		} ) );
		const distance = objectDistance(position,closest.location);
		
		if (distIsNegligible(distance))
		{
			return (
				<div className="location-display" style={{backgroundImage: `url(${closest.images.near})`}} >
					<h1>Du er hos {closest.name}</h1>
					<Forecast lat={this.props.lat} lon={this.props.long} />
				</div>
			);
		}
		return (
			<div className="location-display" style={{backgroundImage: `url(${closest.images.far})`}} >
				<h1><Distance distance={distance} location={closest.name}/></h1>
				<Forecast lat={this.props.lat} lon={this.props.long} />
			</div>
		)
	}
}

export default LocationDisplay;