import React from 'react';

function meterDisplay(meters)
{
	if (meters < 1000)
	{
		return Math.round(meters) + " meter";
	}
	return Math.round(meters / 1000) + " kilometer";
}

const DistanceDisplay = (props) => {
	return <span>Du er {meterDisplay(props.distance)} fra {props.location}</span>;
}

export default DistanceDisplay;