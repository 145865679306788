import React from 'react';
import met from '../apis/met';
import xmlJs from 'xml-js';

import Day from './Day';
import './App.css';

const WEEKDAYS = [
	"Søndag",
	"Mandag",
	"Tirsdag",
	"Onsdag",
	"Torsdag",
	"Fredag",
	"Lørdag"
];

class App extends React.Component
{
	state = {
		days: []
	}
	
	async getMetData(lat, lon) {
		const response = await met.get(
			'locationforecast/1.9/', {
				params: {
					lat,
					lon
				}
			}
		);
		//const parser = new DOMParser();
		//const data = parser.parseFromString(response.data, "application/xml");
		//console.log(data.querySelectorAll('product > *'));
		//const timeData = data.querySelectorAll('product > *');
		const dataJs = xmlJs.xml2js(response.data, {compact: true, spaces: 4});
		//console.log(dataJs);
		
		const timeData = dataJs.weatherdata.product.time;
		//console.log(timeData);
		
		let dayData = [];
		
		timeData.forEach(time => {
			if (time._attributes.from.includes("T12:00:00Z")
				&& time._attributes.to.includes("T12:00:00Z")
				&& time.location.hasOwnProperty('temperature'))
			{
				const date = new Date(time._attributes.from);
				const weekday = date.getDay();
				
				dayData.push({
					weekday: WEEKDAYS[weekday],
					temperature: time.location.temperature._attributes.value,
					cloudiness: time.location.cloudiness._attributes.percent
				});
			}
		});
		
		this.setState({ days: [...dayData] });
	}
	
	componentDidMount() {
		this.getMetData(this.props.lat, this.props.lon);
	}
	
	render() {
		const days = this.state.days.map( (day) => {
			return (
				<Day weekday={day.weekday} temperature={day.temperature} cloudiness={day.cloudiness} />
			);
		})
		
		return (
			<div className="forecast-wrapper">
				<div className="forecast">
					{days}
				</div>
			</div>
		);
	}
}

export default App;