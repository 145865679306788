import React from 'react';
import clear from '../img/sky_clear.png';
import cloudy from '../img/sky_cloudy.png';
import partly from '../img/sky_partly.png';

const Clouds = ({ percent }) => {
	let sky = {
		src: partly,
		alt: "Delvis skyet"
	};
	if (percent < 15)
	{
		sky.src = clear;
		sky.alt = "Klar himmel";
	}
	if (percent > 85) 
	{
		sky.src = cloudy;
		sky.alt = "Tett skyet";
	}
		
	return (
		<img src={sky.src} alt={sky.alt} />
	);
}

export default Clouds;