import React from 'react';
import ReactDOM from 'react-dom';
import LocationDisplay from './LocationDisplay';
import Spinner from './Spinner';

class App extends React.Component
{
	state = { lat: null, long: null, errorMessage: '' };
	
	componentDidMount() {
		window.navigator.geolocation.getCurrentPosition(
			(position) => this.setState({lat: position.coords.latitude, long: position.coords.longitude}),
			(err) => this.setState({errorMessage: err.message})
		);
	}
	
	renderContent() {
		if (this.state.errorMessage && !this.state.lat) {
			return <div>Error: {this.state.errorMessage}</div>;
		}
		if (!this.state.errorMessage && this.state.lat) {
			return <LocationDisplay lat={this.state.lat} long={this.state.long} />
		}
		return <Spinner text="Please accept location request" />;
	}
	
	render() {
		return (
			<div>
				{this.renderContent()}
			</div>
		);
	}
}

ReactDOM.render(
	<App />,
	document.querySelector('#root')
);