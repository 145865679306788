import React from 'react';
import Clouds from './Clouds';
import './Day.css';

const Day = ({ weekday, temperature, cloudiness}) => {
	const temp = {
		min: {
			degrees: 0,
			RGB: 0
		},
		max: {
			degrees: 30,
			RGB: 255
		}
	}
	
	const boundedTemp = Math.max(Math.min(temperature, temp.max.degrees), temp.min.degrees);
	const tempRed = ( boundedTemp - temp.min.degrees ) * 100 / ( temp.max.degrees - temp.min.degrees );
	const tempVal = Math.floor( (tempRed / 100 ) * temp.max.RGB );
	
	// style={{backgroundColor: `rgb(255, ${tempVal}, ${tempVal})`}}>
	return (
		<div className="day-card">
			<span className="weekday">{weekday}</span>
			<Clouds percent={cloudiness} />
			<span className="temperature" style={{color: `rgb(${tempVal}, 0, 0)`}}>{temperature}°C</span>
		</div>
	);
};

export default Day;